<script setup>
const { slugify, locationsByState, locationCatalogSettings } = useLocationData();
const props = defineProps({
  data: Object
})
const locations = await locationsByState(props.data.locationCatalog.divisionsFilter);
// Defaulting to 4 columns
const columnsCount = 4;
const total = Object.keys(locations).length;
const quotient  = Math.floor(total / 4);
const remainder = total % 4;
const columns = [];

let index = 0;
let col = 0;
for (const state in locations) {
  const limit = quotient + (col < remainder ? 1 : 0);  
  if (index < limit) {
    if (!columns[col]) {
      columns[col] = [];
    }    
  }
  else {
    index = 0;
    col++;
    columns[col] = [];    
  }

  const stateObject = {
    'stateSlug': slugify(state),
    'stateName': state,
    'locations': locations[state] 
  }
  columns[col].push(stateObject);
  index++;
}
const activeElementId = ref();


const handleCollapse = (id) => {
  // Scrolling into the element clicked
  const stateElement = document.getElementById(id);  
  activeElementId.value = id;
}

onMounted(() => {
  const accordion = document.getElementById('locationsAccordion')
  if (accordion) {
    accordion.addEventListener('shown.bs.collapse', function () {
      const activeElement = document.getElementById(activeElementId.value);
      activeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });

      // Check if promo banner is on current page
      const promoBanner = document.getElementById('promo-banner')
      const pmOffset = promoBanner ? 35 : 0

      const offset = 70;  
      const y = activeElement.getBoundingClientRect().top +  window.pageYOffset - offset - pmOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    })
  }
})

if (!props.data.locationCatalog.settings) {
  const {data: catalogSettings, error} = await locationCatalogSettings()
  props.data.locationCatalog.settings = catalogSettings;
}

const blockClass = computed(() => {
  return {
    'dark-theme': props.data.options && props.data.options.backgroundColor === 'Black'
  }
})

const hasMatchingDivision = (divisions1, divisionID) => {
  return divisions1.includes(divisionID);
};

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
];
const getSessionDateRange = (location) => {  
  const from = new Date(new Date(location.pulseLocation.firstSessionStartDate).toLocaleString('en', {timeZone: 'UTC'}));
  const to = new Date(new Date(location.pulseLocation.lastSessionEndDate).toLocaleString('en', {timeZone: 'UTC'}));
  return `${monthNames[from.getMonth()]} ${from.getDate()} - ${monthNames[to.getMonth()]} ${to.getDate()}`;
}
</script>

<template>
  <div class="blocks" :class="blockClass">
    <div :class="['locations-catalog', data.locationCatalog.class]">
      <div class="container mx-auto">
        <h3 class="region-group-name mt-6 mb-4" v-if="columns.length > 0 && data.locationCatalog.showCampusTitle">Locations</h3>
        <div class="accordion accordion-flush row" id="locationsAccordion" v-if="columns.length > 0">
          <div class="state-list col-12 col-sm-6 col-md-4 col-xl-3 mb-0" v-for="(col, index) in columns" :key="`column-${col}`">
            <template v-if="data.locationCatalog.showCampPhotos">
              <div class="accordion-item" v-for="(state, stateIndex) in col" :key="`state-${state.stateSlug}`" >
                <template v-if="state.locations.length > 1 || 
                  (state.locations.length === 1 && 
                  state.locations[0].pulseLocation.photoUrls && 
                  state.locations[0].pulseLocation.photoUrls.length > 0 && 
                  state.locations[0].pulseLocation.photoUrls.some(photoUrl => hasMatchingDivision(data.locationCatalog.divisionsFilter, String(photoUrl.divisionID))))">
                <h2 class="accordion-header" :id="`heading-state-${state.stateSlug}`">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#body-state-${state.stateSlug}`" aria-expanded="false" :aria-controls="`body-state-${state.stateSlug}`" @click="handleCollapse(`heading-state-${state.stateSlug}`)">
                    <span class="bi icon-chevron-down"></span>
                    <span>{{ state.stateName }}</span>
                  </button>                  
                </h2>
                </template>
                <div :id="`body-state-${state.stateSlug}`" class="accordion-collapse collapse" :aria-labelledby="`state-${state.stateSlug}`" data-bs-parent="#locationsAccordion">
                  <div class="accordion-body">
                    <ul class="location-list">
                      <li class="location py-2" v-for="location in state.locations" :key="location.id">
                        <template v-if="location.pulseLocation.photoUrls && location.pulseLocation.photoUrls.length > 0">
                          <div v-for="(photoUrl, index) in location.pulseLocation.photoUrls" :key="index">
                            <template v-if="hasMatchingDivision(data.locationCatalog.divisionsFilter, String(photoUrl.divisionID))">
                              <NuxtLink :to="photoUrl.defaultCampPhotoURL" target="_blank">
                                  {{ location.pulseLocation.name }}
                              </NuxtLink>
                              <div class="address text-black-50">{{ location.pulseLocation.addressArea }}</div>
                            </template>
                          </div>
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="accordion-item" v-for="(state, stateIndex) in col" :key="`state-${state.stateSlug}`" >
                <h2 class="accordion-header" :id="`heading-state-${state.stateSlug}`">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#body-state-${state.stateSlug}`" aria-expanded="false" :aria-controls="`body-state-${state.stateSlug}`" @click="handleCollapse(`heading-state-${state.stateSlug}`)">
                    <span class="icon icon-chevron-down"></span>
                    <span>{{ state.stateName }}</span>
                  </button>                  
                </h2>
                <div :id="`body-state-${state.stateSlug}`" class="accordion-collapse collapse" :aria-labelledby="`state-${state.stateSlug}`" data-bs-parent="#locationsAccordion">
                  <div class="accordion-body">
                    <ul class="location-list">
                      <li class="location py-3" v-for="location in state.locations">
                        <NuxtLink class="location-name" :to="`/locations/${location.url}`">
                            {{ location.pulseLocation.name }}
                        </NuxtLink>
                        <div class="location-status" :class="`location-status-type--${slugify(location.pulseLocation.status.typeId)}`">
                          {{ location.pulseLocation.status.name }}
                          <span class="status-desc" v-if="location.pulseLocation.status.typeId == 1 && location.marketingText !== null && location.marketingText !== ''">{{ ` ${location.marketingText}` }}</span> 
                        </div>
                        <div class="address">{{ location.pulseLocation.addressArea }}</div>                        
                        <div class="session-date-range">
                          <span v-if="location.pulseLocation.status.typeId == 1">{{ getSessionDateRange(location) }}</span>
                          <span class="d-inline-block" v-if="location.pulseLocation.residencyOption">                      
                            <span class="icon icon-day" :class="location.pulseLocation.status.typeId == 1 ? 'ms-2' : ''" aria-label="Day camp available"></span>
                            <span class="icon icon-night" v-if="location.pulseLocation.residencyOption.name == 'Day & Overnight'" aria-label="Night camp available"></span>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="programs-container" v-if="data.locationCatalog.showOnline">
          <h3 class="region-group-name mt-6 mb-4">
            {{ data.locationCatalog.settings.flexibleProgramsTitle }}
          </h3>
          <div class="online-programs row">
            <ul class="state-list col-12 col-sm-6 col-md-4 col-xl-3 mb-0 " v-for="menuItem in data.locationCatalog.settings.navigationMenu">
              <li class="mb-3 d-xl-block d-md-block collapsed">
                <div class="state-name d-flex align-content-center" >
                  <div class="collapsed-state-arrow text-secondary flex-grow-0 flex-shrink-0">
                    <span class="bi bi-chevron-right"></span>
                  </div>
                  <NuxtLink :to="menuItem.menuLink">
                    <h4 class="flex-grow-1 flex-shrink-1 font-weight-normal">
                      {{ menuItem.menuText }}
                    </h4>
                  </NuxtLink>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
$animation-duration-default:  0.50s;
.blocks .static-page {
  @include media-breakpoint-down(sm) {
    margin: 40px 0;
  }
  margin: 80px 0;
}

.blocks {
  &.dark-theme {    
    .accordion-button {
      color: $color-secondary;
      .icon-chevron-down {
        color: $color-accent !important;
      }
    }
    .location-list {
      .location {
        a {
          color: $color-secondary;
        }

        .address, .text-black  {
          color: $color-secondary !important;
          opacity: 0.8;
        }        
      }
    }
    .online-programs {
      .bi-chevron-right {
        color: $color-accent !important;
      }
      .state-name {
        a {
          h4 {
            color: $color-secondary;
          }
          
        }
      }
    
    }
  }
}
.locations-catalog, .locations-list {
  .accordion {
    .accordion-item {
      border: none;
      background-color: transparent;
      .accordion-button {
        padding-left: 0px;
        background-color: transparent;        
        &::after {
          background: none;
        }

        &:focus, &:active, &:not(.collapsed) {
          border-color: transparent;
          box-shadow: none;
          background: none;
          color: inherit;
        }

        .icon-chevron-down {
          margin-right: 10px;
          color: #121212;
          
        }

        &:not(.collapsed) {
          .icon-chevron-down:before {
            transition: transform $animation-duration-default ease;
            transform: rotate(0);
            font-weight: 300 !important;
          }
        }

        &.collapsed {
          .icon-chevron-down:before {
            transition: transform $animation-duration-default ease;
            transform: rotate(-90deg);
            font-weight: 300 !important;
          }
        }
      }
      .accordion-body {
        padding-top: 0px;
        .location-list {
          padding-left: 3px;
          .icon-night {
            margin-left: 5px;
            color: $color-subtext;
          }
        }
      }
      .location {
        line-height: $headings-line-height;

        .location-name {
          color: $color-body-text;
        }
        a {
          letter-spacing: 0.3px; // override button/link letter spacing
          text-decoration: none;
        }

        .address {
          font-size: 14px;
          color: $color-subtext;
          line-height: 1.5;
        }

        .session-date-range {
          font-size: 14px;
          color: $color-subtext;
          line-height: 1.5;
        }

        .location-status {
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: -0.12px;

          .status-desc {
            font-style: italic;
          }

          &-type--1 {
            color: #30ad3e !important;
          }

          &--no-dot::before {
            display: none;
          }

          &-type--2, &-type--3 {
            color: #FB9512 !important;
          }
        }
      }
    }

  }
  .region-group-name {
    letter-spacing: 0.3px;
    font-weight: bold;
  }

  .online-programs {
    a {
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
  }

  


  .state-name {
    cursor: pointer;    
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    h4 {
      color: $color-black;
      font-weight: 400;
      display: block;
      font-size: inherit;
      line-height: 1.5;

      a.text-black {
        color: $color-black;
      }
    }

    .text-secondary .bi {
      color: $color-link;
      &:before {
        font-weight: 600 !important;
      }
    }
  }

  .collapsed-state-arrow {
    width: 1.5rem;

    .icon-chevron-down:before {
      transition: transform $animation-duration-default ease;
      transform: rotate(0);
      font-weight: 600 !important;
    }
  }

  .collapsed {
    .collapsed-state-arrow .icon-chevron-down:before {
      transform: rotate(-90deg);
    }

    .location-list {
      transition: max-height $animation-duration-default * 0.4 ease;
      
    }
  }

  .virtual-online {

    &__name {
      letter-spacing:.3rem;

      :first-child {
        width: 1.5rem;

        .icon::before {
          transform: rotate(-90deg);
        }
      }

      a {        
        letter-spacing: 0.3px;
        color: $color-black;
      }
    }
  }
}

</style>